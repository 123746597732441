<template>
    <div id="app_template_add_frame_id">
        <!-- 多媒体消息新增模板-新增帧  -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>多媒体消息新增帧</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-row>
                        <el-col :span="4">
                            请选择要上传的多媒体文件
                        </el-col>
                        <el-col :span="20">
                            <!-- show-file-list:是否显示已上传文件列表  默认值 true
                                    multiple:是否支持多选文件 
                                    data:上传时附带的额外参数
                                    auto-upload	是否在选取文件后立即进行上传
                            -->
                            <el-upload ref="uploadFile" style="display: inline-block;"
                                :action="upload_url" :multiple="false" :limit="1" :auto-upload="false"
                                accept=".jpg, .jpeg, .gif, .bmp, .png, .aac, .mp3, .3gp, .mp4, .amr, .mpeg, .JPG, .JPEG, .GIF, .BMP, .PNG, .AAC, .MP3, .3GP, .MP4, .AMR, .MPEG"
                                
                                :on-success="uploadResponseResult"
                                :on-error="uploadResponseResultFail"
                                :file-list="fileList"
                                :on-change="uploadChange"
                                :on-remove="uploadChange"
                                :before-upload="handleFileBefore">
                                <el-button size="mini" type="primary" plain @click="upload()">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">
                                    图片尺寸建议：宽度800px，高度范围300px~600px</br>
                                    视频尺寸建议：640*360</br>
                                    建议连续添加多种等宽图片或视频素材，若中间穿插文字会影响展示效果
                                </div>
                            </el-upload>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="4" style="padding-top:10px;">
                            请输入文本信息
                        </el-col>
                        <el-col :span="20" style="padding-top:5px;">
                            <el-input type="textarea" v-model.trim="form_data.content_1" placeholder="在消息中显示的文本信息" size="mini"></el-input>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24" style="text-align:center;padding-top:10px;">
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-col>
                    </el-row>
                </el-row>

            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';
import {
    htmlDecode,
    getItem
} from '../../api/tool.js';

export default {
    props:{
        prop_frame_template_i: 0,
        prop_frame_channel_id: '',//通道id
        prop_frame_template_id: '',//模板id
        prop_group_id_1: 0,//帧顺序
    },
    watch:{//监听props
        prop_frame_template_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                // setTimeout(() => {
                // }, 1);
            }
        }
    },

    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的宽度
            content_heigth:500,//内容的高度

            form_data:{
                content_1:'',//文本信息
            },

            upload_url:"",//上传url
            fileList:[],//文件列表
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },

        //上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传
        handleFileBefore(file){
            console.log("file="+file.type);
            //重构平台，图片格式限制jpg、jpeg、gif、bmp、png音频格式限制AAC、MP3；视频格式限制3gp、mp4、amr、mpeg；
            const imgType = file.name.endsWith('.jpg') || file.name.endsWith('.jpeg')||file.name.endsWith('.gif') || file.name.endsWith('.bmp') || file.name.endsWith('.png') || file.name.endsWith('.JPG') || file.name.endsWith('.JPEG')||file.name.endsWith('.GIF') || file.name.endsWith('.BMP') || file.name.endsWith('.PNG');
            const audioType = file.name.endsWith('.aac') || file.name.endsWith('.mp3') || file.name.endsWith('.AAC') || file.name.endsWith('.MP3');
            const voiceType = file.name.endsWith('.3gp') || file.name.endsWith('.mp4')||file.name.endsWith('.amr') || file.name.endsWith('.mpeg') || file.name.endsWith('.3GP') || file.name.endsWith('.MP4')||file.name.endsWith('.AMR') || file.name.endsWith('.MPEG');
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!imgType && !audioType && !voiceType) {
                this.$message.error('上传文件只能是 png或pdf 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 2MB!');
            }

            if((imgType || audioType || voiceType)&& isLt2M){
                return new Promise((resolve, reject) => {
                    // 拼接上传url
                    // 此处动态配置action URL
                    this.upload_url = API.getInterfaceUrl()+"/MediaTemplateServlet?param=addTemplateFrame&session="+getItem("qxt_session")
                    +"&channel_id="+this.prop_frame_channel_id
                    +"&template_id="+this.prop_frame_template_id+"&group_id_1="+this.prop_group_id_1+"&content="+encodeURIComponent(this.form_data.content_1)
                    +"&group_id_2=1&callback="+( new Date().getTime()+ "_" + Math.ceil(Math.random() * 1000) );
                    // console.log(this.upload_url);
                    // dom上传地址更新完成后，触发上传
                    this.$nextTick(() => resolve());
                });
            }else{
                return (imgType || audioType || voiceType) && isLt2M;
            }
        },
        //添加视频帧
        onSubmit(){
            console.log("添加视频帧 file_num:"+this.fileList.length);
            if(API.isEmtry(this.form_data.content_1) && this.fileList.length == 0){
                this.$message.error("请选择多媒体文件或填写文本信息");
                return;
            }
            if(this.fileList.length == 0){
                API.MediaTemplateServlet({
                    param: "addTemplateFrame",
                    channel_id:this.prop_frame_channel_id,
                    template_id:this.prop_frame_template_id,
                    group_id_1:this.prop_group_id_1,
                    content:this.form_data.content_1,
                    group_id_2:1,
                }).then((res) => {
                    //console.log(res);
                    if (res.recode === 0) {
                        this.$message({
                            message: "恭喜您，提交成功",
                            type: "success",
                            offset: 280
                        });
                        this.dialog_visible = false;//弹出框是否可见   false:不可见
                        this.$emit("add_frame", res.template_id, this.prop_group_id_1);
                    }
                });
            }else{
                this.$refs.uploadFile.submit();
            }
        },
        //上传 - 文件上传或从列表中移除时触发 
        uploadChange(file, fileList){
            // console.log("文件上传或从列表中移除时触发 "+JSON.stringify(fileList));
            this.fileList = fileList;
        },

        //上传 按钮触发方法
        upload(){
            // console.log("上传 按钮触发方法");
            
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            // this.upload_param:{},//上传带的额外参数
        },
        //上传成功响应结果
        uploadResponseResult(res, file, fileList) {
            //response这个
            // console.log("上传响应结果res:"+JSON.stringify(res));
            if(res){
                // res = JSON.parse(htmlDecode(res));
                if(res.recode == 0){
                    this.$message({
                        message: "恭喜您，提交成功",
                        type: "success",
                        offset: 280
                    });
                    
                    this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录

                    this.dialog_visible = false,//遮盖层是否可见  false:不可见
                    this.$emit("add_frame", res.template_id, this.prop_group_id_1);
                }else{
                    this.$message({
                        message: res.text,
                        type: "error",
                        offset: 280
                    });
                }
            }else{
                this.$message({
                    message: "文件上传失败，请稍后再试哦",
                    type: "error",
                    offset: 280
                });
            }
            
        },
        //上传失败响应结果
        uploadResponseResultFail(err, file, fileList){
            console.log("文件上传失败响应结果");
            this.$message({
                message: "文件上传失败了，请稍后再试哦",
                type: "error",
                offset: 280
            });
        },
        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
            this.$emit("add_frame", this.prop_frame_template_id, this.prop_group_id_1);
        },

    }
};
</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>